<template>
  <div class="pa-4">
    <div class="pb-3 headline">
      Data Explorer
    </div>
    <div class="pb-3">
      <div @click="toggleShowCustomize()" class="customize-button">
        <v-layout align-center>
          <v-icon v-html="showCustomize ? 'expand_more' : 'chevron_right'"></v-icon>
          <div>Customize</div>
        </v-layout>
      </div>
      <div v-if="showCustomize" class="ml-5 mb-3">
        <div class="mt-3">Columns</div>
        <v-layout row wrap class="pb-3">
          <v-flex
            v-for="header in allHeaders"
            xs6
            :key="header.value"
          >
            <v-checkbox
              v-model="columns"
              @change="onColumnsChange"
              :label="header.text"
              :value="header.value"
              hide-details
            ></v-checkbox>
          </v-flex>
        </v-layout>
        <div class="mt-3">Data</div>
        <v-checkbox
          v-model="showRemovedGenomes"
          label="Show removed genomes"
          hide-details
        ></v-checkbox>
      </div>
    </div>
    <v-data-table
      :headers="headers"
      :items="insightsRecords"
      :total-items="totalRecords"
      item-key="job_run_id_sample_genome"
      :pagination.sync="pagination"
      :rows-per-page-items="[5, 10, 25]"
      class="elevation-1"
      :loading="loading"
      @update:pagination="paginationChanged"
    >
      <template slot="no-data">
        <v-layout justify-center>
          <div v-if="loading">Loading...</div>
          <div v-else>No data</div>
        </v-layout>
      </template>
      <template v-slot:pageText="props">
        {{ props.pageStart }} - {{ props.pageStop }}
      </template>
      <template slot="headers" slot-scope="props">
        <tr>
          <th
            v-for="header in props.headers"
            :key="header.text"
          >
            <v-icon small v-if="header.sortable">arrow_upward</v-icon>
            {{ header.text }}
          </th>
        </tr>
        <tr class="grey lighten-3">
          <th
            v-for="header in props.headers"
            :key="header.text"
            class="table-header-filter"
          >
            <v-layout row wrap fill-height>
              <v-flex xs12 v-if="filters.hasOwnProperty(header.value)" :key="filters[header.value]?.key">
                <v-text-field
                  v-model="filters[header.value].model"
                  append-icon="search"
                  :label="filters[header.value].label"
                  single-line
                  hide-details
                ></v-text-field>
              </v-flex>
            </v-layout>
          </th>
        </tr>
      </template>
      <template slot="items" slot-scope="props">
        <tr :class="props.index % 2 === 0 ? 'grey lighten-4 noborder' : 'noborder'">
          <td class="data-explorer-row" v-if="isColVisible('job_run_name')">
            <div>
              <a
                :href="`/app/main/results/${props.item.job_run_id}`"
                target="_blank"
                class="job-run-link"
              >
                {{ props.item.user_provided_name || props.item.job_run_id }}
              </a>
            </div>
            <div>
              Lab: {{ getLabName(props.item.org_id) }}
            </div>
          </td>
          <td class="data-explorer-row" v-if="isColVisible('date')">
            {{ props.item.created.toLocaleDateString() }}
          </td>
          <td class="data-explorer-row" v-if="isColVisible('sample_name')">
            {{ props.item.sample_name }}
          </td>
          <td class="data-explorer-row" v-if="isColVisible('genome_name')">
            <PopOut
              v-if="props.item.is_ignored"
              icon="visibility_off"
              icon-color="orange"
            >
              This genome is removed from Insights and future outbreak analyses
            </PopOut>
            {{ props.item.genome_name }}
          </td>
          <td class="data-explorer-row" v-if="isColVisible('refmlst_address')">{{ props.item.refmlst_address }}</td>
          <td class="data-explorer-row" v-if="isColVisible('sequence_type')">{{ props.item.sequence_type }}</td>
          <td class="data-explorer-row" v-if="isColVisible('replicon_to_amr_mapping')">
            <v-layout
              row
              v-for="(markers, hostOrPlasmid) in props.item.replicon_to_amr_mapping"
              class="my-1 plasmid-determinant-row"
              :key="hostOrPlasmid"
            >
              <v-flex xs3 class="plasmid-determinant-plasmid">
                {{ hostOrPlasmid }}:
              </v-flex>
              <v-flex xs9>
                <span
                  v-for="marker in [...markers].sort()"
                  :key="marker"
                >
                  {{ marker }};
                </span>
                <span v-if="markers.size === 0">
                  None
                </span>
              </v-flex>
            </v-layout>
          </td>
          <td class="data-explorer-row" v-if="isColVisible('user_provided_metadata')">
            <v-layout
              row
              v-for="(metadataVal, metadataKey) in props.item.user_provided_metadata"
              class="my-1"
              :key="metadataKey"
            >
              <v-flex class="">
                {{ formatMetadataKey(metadataKey) }}:
              </v-flex>
              <v-flex>
                {{ formatMetadataValue(metadataVal, metadataKey) }}
              </v-flex>
            </v-layout>
          </td>
          <td class="data-explorer-row" v-if="isColVisible('options')">
            <v-btn
              class="text-capitalize"
              @click="props.expanded = !props.expanded"
            >{{ props.expanded ? 'Hide' : 'Options' }}</v-btn>
          </td>
        </tr>
      </template>
      <template v-slot:expand="props">
        <v-card
          flat
          :class="props.index % 2 === 0 ? 'grey lighten-4 noborder px-2' : 'noborder px-2'"
        >
          <v-card-text>
            <div class="font-weight-bold my-2">Options for {{ props.item.sample_name }}: {{ props.item.genome_name }}</div>
            <v-layout justify-center>
              <PopOut
                v-if="!hasPermissionToIgnore(props.item)"
                icon="warning"
                icon-color="orange"
                class="mr-2"
              >
                Users can only ignore samples that they submitted or in labs that they manage.
              </PopOut>
              <v-checkbox
                :input-value="props.item.is_ignored"
                :disabled="!hasPermissionToIgnore(props.item) || ignoreLoading"
                hide-details
                @change="(val) => ignoreFromOutbreakAnalysis(props.item.job_run_id, props.item.sample_name, props.item.genome_taxon_id, val)"
              >
                <template v-slot:label>
                  <span class="ignore-outbreak-analysis-label mr-1">Remove genome from Insights and future outbreak analyses</span>
                  <PopOut
                    icon="info"
                    v-if="hasPermissionToIgnore(props.item)"
                  >
                    This will remove the {{ props.item.sample_name }}: {{ props.item.genome_name }} genome from future analyses. It can be undone by toggling "Show removed genomes" in the "Customize" section and untoggling this option. Note if the sample contains multiple genomes, they each must be removed individually.
                  </PopOut>
                </template>
              </v-checkbox>
            </v-layout>
          </v-card-text>
        </v-card>
      </template>
    </v-data-table>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from "vue-property-decorator";
import { Store } from "vuex";
import { components, paths } from "@bugseq-site/app/src/lib/api/api";
import { debounce } from "@bugseq-site/shared/src/lib/utils";
import PopOut from "@bugseq-site/shared/src/components/PopOut.vue";
import { dispatchGetInsightsRecords, dispatchGetLabMembership, dispatchUpdateGenomeSummaryIgnore } from "@bugseq-site/app/src/store/modules/api/actions";
import { readLabs, readUserProfile } from "@bugseq-site/app/src/store/modules/api/getters";

@Component({
  components: { PopOut },
})
export default class DataExplorer extends Vue {
  private loading = false
  private ignoreLoading = false

  private allHeaders = [
    { text: "Analysis", align: "left", sortable: false, value: "job_run_name", width: '20%' },
    { text: "Date", align: "left", sortable: true, value: "date", width: '10%' },
    { text: "Sample", align: "left", sortable: false, value: "sample_name", width: '20%' },
    { text: "Genome", align: "left", sortable: false, value: "genome_name", width: '20%' },
    { text: "Outbreak Address", align: "left", sortable: false, value: "refmlst_address" },
    {
      text: "Sequence Type",
      align: "left",
      sortable: false,
      value: "sequence_type",
      width: '10%',
    },
    { text: "Plasmids and AMR", align: "left", sortable: false, value: "replicon_to_amr_mapping" },
    { text: "Metadata", align: "left", sortable: false, value: "user_provided_metadata" },
    { text: "Options", align: "left", sortable: false, value: "options" },
  ];
  private headers = this.allHeaders.filter(
    h => !["date", "sequence_type", "user_provided_metadata"].includes(h.value),
  );
  private columns = this.allHeaders.filter(
    h => !["date", "sequence_type", "user_provided_metadata"].includes(h.value),
  ).map(h => h.value);

  private pagination = {
    page: 1,
    rowsPerPage: 10,
    sortBy: null,
    descending: true,
  };

  private filters = {
    job_run_name: { key: "job_run_name", label: "Analysis", model: "" },
    organization: { key: "organization", label: "Lab", model: "" },
    sample_name: { key: "sample_name", label: "Sample", model: "" },
    genome_name: { key: "genome_name", label: "Genome", model: "" },
    replicon_to_amr_mapping: { key: "replicon_to_amr_mapping", label: "Plasmids and AMR", model: "" },
    user_provided_metadata: { key: "user_provided_metadata", label: "Metadata", model: "" },
  }

  private showCustomize = false;
  private showRemovedGenomes = false;
  private insightsRecords: components['schemas']['InsightsRecordResponse'][] = [];
  private totalRecords: number = 100000;

  public async mounted() {
    dispatchGetLabMembership(this.$store)
  }

  @debounce(300)
  @Watch("showRemovedGenomes")
  @Watch("filters", { deep: true })
  public async filtersChanged() {
    // updating does not trigger paginationChanged.
    // reset page as it doesn't make sense to continue with old pagination.
    this.pagination.page = 1;
    return this.paginationChanged(this.pagination);
  }

  public async paginationChanged(pagination) {
    this.loading = true;

    const { page, rowsPerPage } = pagination;

    const opts: paths['/v1/explore/summary_v2']['get']['parameters']['query'] = {
      limit: rowsPerPage,
      skip: (page - 1) * rowsPerPage,
    };

    for (const filter of Object.values(this.filters)) {
      if (filter.model) {
        opts[filter.key] = filter.model
      }
    }

    if (this.showRemovedGenomes) {
      opts.include_ignored = true
    }

    try {
      const resp = await dispatchGetInsightsRecords(this.$store, opts);
      if (resp) {
        this.insightsRecords = resp!.records.map(ir => ({
          ...ir,
          job_run_id_sample_genome: ir.job_run_id + "-" + ir.sample_name + "-" + ir.genome_name,
        }))

        if (!resp.has_more) {
          this.totalRecords = (pagination.page-1) * pagination.rowsPerPage + resp.records.length
        } else {
          this.totalRecords = 100000
        }
      }
    } finally {
      this.loading = false;
    }
  }

  private toggleShowCustomize() {
    this.showCustomize = !this.showCustomize
  }

  private onColumnsChange(updatedColumns) {
    this.headers = this.allHeaders.filter(h => updatedColumns.includes(h.value));
  }

  private isColVisible(colName) {
    return this.columns.includes(colName)
  }

  get user() {
    return readUserProfile(this.$store);
  }

  private getLabName(orgId: string | null): string {
    if (!orgId) {
      return "None"
    }

    const lab = readLabs(this.$store).find((lab) => lab.id === orgId)
    if (!lab) {
      return "None"
    }

    return lab.name
  }

  private hasPermissionToIgnore(record) {
    if (!this.user) {
      return false
    }

    if (record.owner_id === this.user.id) {
      return true
    }

    if (readLabs(this.$store).find(l => l.id === record.org_id)?.manager_id === this.user.id) {
      return true
    }

    return false
  }

  private async ignoreFromOutbreakAnalysis(jobId: string, sampleName: string, genomeTaxonId: number, isIgnored: boolean) {
    this.ignoreLoading = true
    try {
      await dispatchUpdateGenomeSummaryIgnore(this.$store, {
        jobId,
        payload: {
          sample_name: sampleName,
          genome_taxon_id: genomeTaxonId,
          is_ignored: isIgnored,
        },
      })

      // we reload everything because updating the record would mess up pagination.
      // i.e. if we're querying for un-hidden genomes
      //      and the user deletes a genome
      //      and then clicks 'next-page'
      //      a record would get lost
      await this.paginationChanged(this.pagination)
    } finally {
      this.ignoreLoading = false
    }
  }

  private formatMetadataKey(metadataKey: string): string {
    if (metadataKey === "negative_control") {
      return "Negative Control"
    }

    return metadataKey
  }

  private formatMetadataValue(metadataVal: string | boolean, metadataKey: string): string {
    // negative_control is the only bugseq-controlled key, and the only one
    // that may be a boolean.
    if (metadataKey === "negative_control") {
      return metadataVal ? "True" : "False"
    }

    if (typeof metadataVal !== "string") {
      throw new Error(`unrecognized value type: ${metadataVal}`)
    }

    return metadataVal
  }
}
</script>

<style scoped>
.customize-button {
  cursor: pointer;
}

table.v-table tbody tr.noborder {
  border-top: none;
}

.table-header-filter {
  height: 100%;
}

.plasmid-determinant-plasmid {
  min-width: 90px;
}

.plasmid-determinant-row {
  min-width: 300px;
}

.job-run-link {
  word-break: break-all;
}

.ignore-outbreak-analysis-label {
  font-size: 13px;
}

/* not sure why scoped styles don't work properly here... */
:global(table.v-table tbody td.data-explorer-row) {
  height: 36px;
  padding-top: 10px;
  padding-bottom: 10px;
}
</style>
