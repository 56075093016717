<template>
  <v-card class="elevation-12 mt-4">
    <v-toolbar dark color="primary">
      <v-toolbar-title>One last step</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>

    <v-card-text>
      <div class="my-3">
        We need to collect a bit more information before you can start
        analyzing data.
      </div>
      <v-form ref="form" lazy-validation>
        <v-text-field
          class="required"
          label="First Name"
          name="First Name"
          v-model="firstName"
          v-validate="'required'"
          :error-messages="getError(fields, errors, 'First Name')"
        ></v-text-field>
        <v-text-field
          class="required"
          label="Last Name"
          name="Last Name"
          v-model="lastName"
          v-validate="'required'"
          :error-messages="getError(fields, errors, 'Last Name')"
        ></v-text-field>
        <v-text-field
          class="required"
          label="Affiliation"
          name="Affiliation"
          v-model="affiliation"
          v-validate="'required'"
          :error-messages="getError(fields, errors, 'Affiliation')"
        ></v-text-field>
        <v-text-field
          label="Signup Code (not required)"
          name="Signup Code"
          v-model="signupCode"
        ></v-text-field>
        <v-text-field
          label="Email"
          name="Email"
          type="email"
          v-model="email"
          disabled
        ></v-text-field>
        <v-layout align-center>
          <v-checkbox
            class="required"
            v-model="terms"
            v-validate="'required:true'"
            name="Accept Terms"
            @change="
              () => {
                this.showTermsError = true;
              }
            "
            :error-messages="
              showTermsError ? errors.collect('Accept Terms') : ''
            "
          >
            <template slot="label">
              <div>
                I agree to the
                <a
                  target="_blank"
                  href="https://docs.bugseq.com/legal/terms-of-use"
                  @click.stop
                >
                  Terms of Use
                </a>
                and
                <a
                  target="_blank"
                  href="https://docs.bugseq.com/legal/privacy-policy"
                  @click.stop
                >
                  Privacy Policy
                </a>
                .
              </div>
            </template>
          </v-checkbox>
        </v-layout>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        @click="submit"
        class="text-capitalize"
        :disabled="errors.items.length > 0 || inProgress"
      >
        Register
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { fetchAuthSession } from "aws-amplify/auth";
import { components } from "@bugseq-site/app/src/lib/api/api";
import { redirectToSignIn } from "@bugseq-site/app/src/lib/api/auth";
import { dispatchCreateOpenUser } from "@bugseq-site/app/src/store/modules/api/actions";
import { urlSafeBase64Decode } from "@bugseq-site/shared/src/lib/utils";
import type { InviteLinkParameters } from "@bugseq-site/app/src/lib/types";

@Component({
  methods: {
    getError: (fields, errors, field) => {
      if (!(field in fields)) {
        return "";
      }
      if (!fields[field].dirty) {
        return "";
      }
      return errors.collect(field);
    },
  },
})
export default class CreateUser extends Vue {
  public firstName: string = "";
  public lastName: string = "";
  public email: string = "";
  public affiliation: string = "";
  public terms: boolean = false;
  public signupCode: string = "";
  public showTermsError: boolean = false;

  public inProgress = false;

  public async mounted() {
    try {
      const session = await fetchAuthSession();
      this.email = session.tokens!.idToken!.payload.email as string;
    } catch (e) {
      redirectToSignIn(this.$router);
    }
    if (this.$route.query.personalizedcode) {
      const code = this.$route.query.personalizedcode as string;
      const params: InviteLinkParameters = urlSafeBase64Decode(code)

      this.firstName = params.firstName as string;
      this.lastName = params.lastName as string;
      this.affiliation = params.affiliation as string;
      this.terms = params.terms;
      this.signupCode = params.signupCode as string;
    }
    this.$validator.validateAll();
  }

  public reset() {
    this.firstName = "";
    this.lastName = "";
    this.terms = false;
  }

  public async submit() {
    if (await this.$validator.validateAll()) {
      this.inProgress = true;

      const updatedProfile: components["schemas"]["UserCreateRequest"] = {
        first_name: this.firstName,
        last_name: this.lastName,
        affiliation: this.affiliation,
        signup_code: this.signupCode || null,
      };
      try {
        await dispatchCreateOpenUser(this.$store, updatedProfile);

        const redirect = (this.$route.query.redirect || "/app/main/dashboard") as string;
        this.$router.push(redirect);
      } catch (error) {
        // ignore the error because it's already been surfaced to the user
      } finally {
        this.inProgress = false;
      }
    }
  }
}
</script>

<style scoped>
.required >>> label::after {
  content: " *";
  color: red;
}
</style>
