import { getStoreAccessors } from 'typesafe-vuex'
import { ApiState } from './state'
import { RootState } from '../../state'

export const getters = {
  userProfile: (state: ApiState) => state.userProfile,
  jobResults: (state: ApiState) => state.jobResults,
  labs: (state: ApiState) => state.labs,
  labInvites: (state: ApiState) => state.labInvites
}

const { read } = getStoreAccessors<ApiState, RootState>('api')

export const readUserProfile = read(getters.userProfile)
export const readJobResults = read(getters.jobResults)
export const readLabs = read(getters.labs)
export const readLabInvites = read(getters.labInvites)
