<template>
  <v-app>
    <v-layout justify-center>
      <v-flex shrink class="google-font mt-5">
        Your download will begin shortly...
      </v-flex>
    </v-layout>
  </v-app>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { dispatchGetStaticFileLink } from "@bugseq-site/app/src/store/modules/api/actions";

@Component({
  metaInfo() {
    return {
      title: "Download",
    };
  },
})
export default class DownloadStatic extends Vue {
  private async mounted() {
    const filename = this.$route.params.filename
    const fileLink = await dispatchGetStaticFileLink(this.$store, { filename })
    const newWindow = window.open(fileLink, "_blank");
    if (newWindow) {
      newWindow.focus();
    }
  }
}
</script>
