export class BugSeqApiError extends Error {}

interface RawBugSeqApiError {
  detail: string
}

// https://stackoverflow.com/a/14426274
export function isRawBugSeqApiError (object: any): object is RawBugSeqApiError {
  return 'detail' in object
}
