<template>
  <div class="pa-4 mt-4">
    <div class="subheading font-weight-bold">
      Data Access
    </div>
    <div class="px-4 mt-2 mb-5">
      <div class="subheading my-3">
        Control who in the lab has access to data. By default, analysis data is only accessible by the user who submitted the analysis. Only lab managers can adjust this setting.
      </div>
      <v-alert
        :value="!isLabManager()"
        class="font-weight-bold my-3"
        type="info"
        outline
      >
        Only lab managers can update settings for a lab
      </v-alert>
      <v-checkbox
        v-model="lab.auto_share_analyses"
        @change="updateAutoShareAnalyses"
        label="Automatically Share Analyses with Lab Members"
        :disabled="!isLabManager()"
      >
        <template slot="label">
          <span class="mr-2">
            Automatically Share Analyses with Lab Members </span
          ><PopOut icon="help"
            >When any analysis completes, all lab members will get an email with a
            link to access results.</PopOut
          >
        </template>
      </v-checkbox>
    </div>
    <div class="subheading font-weight-bold">
      Metadata
    </div>
    <div class="px-4 mt-2 mb-5">
      <div class="subheading my-3">
        Lab members can submit metadata along with sequencing data which is incorporated into various parts of the platform.
      </div>
      <div class="subheading my-3">
        Metadata is restricted to specific keys. Each sample can specify a different value. For example, each sample may have an associated "facility", where the facility is different for each sample.
      </div>
      <div class="subheading my-3">
        Here you can select which metadata keys are accepted. <span class="font-weight-bold">Please be careful not to add any keys that are likely to contain PHI.</span>
      </div>
      <v-alert
        :value="!isLabManager()"
        class="font-weight-bold my-3"
        type="info"
        outline
      >
        Only lab managers can update settings for a lab
      </v-alert>
      <div class="subheading my-3" v-if="lab.metadata_keys.length > 0">
        Existing Keys:
      </div>
      <v-layout>
        <v-chip
          v-for="metadataKey in lab.metadata_keys"
          :key="metadataKey"
          close
          @input="removeMetadataKey(metadataKey)"
        >
          {{ metadataKey }}
        </v-chip>
      </v-layout>
      <div class="subheading mt-3">
        <div class="font-weight-bold mb-3">
          Add New Keys
        </div>
        <div>
          Keys must start with a letter, can only container letters/numbers/_, and must be 24 characters or less.
        </div>
        <v-alert
          :value="lab.metadata_keys.length === metadataKeyLimit"
          class="font-weight-bold my-3"
          type="warning"
          outline
        >
          Only {{ metadataKeyLimit }} keys can be specified, no more can be added
        </v-alert>
      </div>
      <v-form>
        <v-layout class="mb-2">
          <v-text-field
            name="Metadata Key"
            placeholder="facility_id"
            v-model="newMetadataKey"
            v-validate="'max:24|regex:^[a-zA-Z][a-zA-Z0-9_]*$'"
            :error-messages="errors.first('Metadata Key')"
          ></v-text-field>
          <v-btn
            class="text-capitalize"
            @click=addMetadataKey(newMetadataKey)
            :disabled="lab.metadata_keys.length === metadataKeyLimit || errors.items.length > 0"
          >Add</v-btn>
        </v-layout>
        <v-btn
          :disabled="!dirty"
          class="ml-0 text-capitalize"
          @click="updateMetadataKeys()"
          color="primary"
        >
          Save
        </v-btn>
      </v-form>
    </div>
    <div class="subheading font-weight-bold">
      Notifications
    </div>
    <div class="px-4 mt-2 mb-5">
      <div class="subheading my-3">
        You will always receive an email when an analysis is shared directly with you. You can also receive notifications analyses are shared with the whole lab.
      </div>
      <v-checkbox
        v-model="this.lab.member_preferences.emails_enabled_org_share"
        @change="updateMembership"
        hide-details
        class="mb-1 mt-0"
      >
        <template slot="label">
          <span class="black--text">Receive an Email When an Analysis is Shared with the Entire Lab</span>
        </template>
      </v-checkbox>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { Store } from "vuex";
import {
  dispatchUpdateLab,
  dispatchGetLabMembership,
  dispatchUpdateLabMembership,
} from "@bugseq-site/app/src/store/modules/api/actions";
import { readUserProfile } from "@bugseq-site/app/src/store/modules/api/getters";
import PopOut from "@bugseq-site/shared/src/components/PopOut.vue";

const ComponentProps = Vue.extend({
  props: {
    lab: Object,
  },
});

@Component({
  components: {
    PopOut,
  }
})
export default class LabTabDataAccessAndNotifications extends ComponentProps {
  private newMetadataKey: string = ""
  private dirty: boolean = false
  private metadataKeyLimit: number = 5

  private isLabManager() {
    const user = readUserProfile(this.$store)
    if (!user || !this.lab) {
      return false
    }

    return this.lab.manager_id === user.id
  }

  private updateMembership() {
    dispatchUpdateLabMembership(this.$store, {
      id: this.lab.id,
      payload: {
        emails_enabled_org_share: this.lab.member_preferences.emails_enabled_org_share,
      },
    });
  }

  private async updateAutoShareAnalyses() {
    await dispatchUpdateLab(this.$store, {
      id: this.lab.id,
      lab: { auto_share_analyses: this.lab.auto_share_analyses },
    });
    await dispatchGetLabMembership(this.$store); // refresh, because the resp of create doesn't have members
  }

  private addMetadataKey(metadataKey: string) {
    if (!metadataKey) {
      return
    }
    this.lab.metadata_keys.push(metadataKey)
    this.newMetadataKey = ""
    this.dirty = true
  }

  private removeMetadataKey(metadataKey: string) {
    this.lab.metadata_keys = this.lab.metadata_keys.filter(k => k !== metadataKey)
    this.dirty = true
  }

  private async updateMetadataKeys() {
    await dispatchUpdateLab(this.$store, {
      id: this.lab.id,
      lab: { metadata_keys: this.lab.metadata_keys },
    });
    await dispatchGetLabMembership(this.$store); // refresh, because the resp of create doesn't have members
    this.dirty = false
    this.newMetadataKey = ""
  }
}
</script>
